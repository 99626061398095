// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baas_styles_root__F4Z2C {\n  margin-bottom: var(--padding-medium);\n}\n\n.baas_styles_title__53AFm {\n  margin: 0;\n  font-size: 28px;\n  font-weight: 400;\n}\n\n.baas_styles_titleBlock__ggDIW {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n.baas_styles_subtitleBlock__rqyeg {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.baas_styles_contractDropdown__NCrbG {\n  min-width: 240px !important;\n  padding-top: 7px !important;\n  padding-left: 32px !important;\n  margin: 8px 0 !important;\n}\n\n@media (max-width: 767px) {\n  .baas_styles_titleBlock__ggDIW,\n  .baas_styles_subtitleBlock__rqyeg {\n    display: block;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/containers/PageHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,SAAS;EACT,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,6BAA6B;EAC7B,wBAAwB;AAC1B;;AAEA;EACE;;IAEE,cAAc;EAChB;AACF","sourcesContent":[".root {\n  margin-bottom: var(--padding-medium);\n}\n\n.title {\n  margin: 0;\n  font-size: 28px;\n  font-weight: 400;\n}\n\n.titleBlock {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n.subtitleBlock {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.contractDropdown {\n  min-width: 240px !important;\n  padding-top: 7px !important;\n  padding-left: 32px !important;\n  margin: 8px 0 !important;\n}\n\n@media (max-width: 767px) {\n  .titleBlock,\n  .subtitleBlock {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "baas_styles_root__F4Z2C",
	"title": "baas_styles_title__53AFm",
	"titleBlock": "baas_styles_titleBlock__ggDIW",
	"subtitleBlock": "baas_styles_subtitleBlock__rqyeg",
	"contractDropdown": "baas_styles_contractDropdown__NCrbG"
};
export default ___CSS_LOADER_EXPORT___;
