// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baas_styles_card__s7XLz {\n  position: relative;\n  min-height: 400px;\n  border-color: var(--color-light-red) !important;\n}\n\n.baas_styles_spinner__AzbfQ {\n  border-radius: 0 0 4px 4px !important;\n}\n\n.baas_styles_contentBlock__B8PBJ {\n  padding: 16px;\n}\n\n.baas_styles_table__HQ5w2 {\n  width: 100%;\n  margin-bottom: 16px;\n  overflow-x: auto;\n}\n\n.baas_styles_table__HQ5w2::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.baas_styles_table__HQ5w2::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgba(0 0 0/ 80%);\n}\n\n.baas_styles_table__HQ5w2::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgba(0 0 0 / 10%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/O365UsagePageContent/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,+CAA+C;AACjD;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;AACrC","sourcesContent":[".card {\n  position: relative;\n  min-height: 400px;\n  border-color: var(--color-light-red) !important;\n}\n\n.spinner {\n  border-radius: 0 0 4px 4px !important;\n}\n\n.contentBlock {\n  padding: 16px;\n}\n\n.table {\n  width: 100%;\n  margin-bottom: 16px;\n  overflow-x: auto;\n}\n\n.table::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.table::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgba(0 0 0/ 80%);\n}\n\n.table::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgba(0 0 0 / 10%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "baas_styles_card__s7XLz",
	"spinner": "baas_styles_spinner__AzbfQ",
	"contentBlock": "baas_styles_contentBlock__B8PBJ",
	"table": "baas_styles_table__HQ5w2"
};
export default ___CSS_LOADER_EXPORT___;
