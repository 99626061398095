// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baas_styles_chart__\\+GFys {\n  position: relative;\n  overflow: auto;\n  height: 350px;\n}\n\n.baas_styles_tableBlock__BW5ty {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  overflow-x: auto;\n}\n\n.baas_styles_tableBlock__BW5ty::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.baas_styles_tableBlock__BW5ty::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 80%);\n}\n\n.baas_styles_tableBlock__BW5ty::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 10%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/UsagePageContent/reports/UsageSummaryReport/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC","sourcesContent":[".chart {\n  position: relative;\n  overflow: auto;\n  height: 350px;\n}\n\n.tableBlock {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  overflow-x: auto;\n}\n\n.tableBlock::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.tableBlock::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 80%);\n}\n\n.tableBlock::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 10%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": "baas_styles_chart__+GFys",
	"tableBlock": "baas_styles_tableBlock__BW5ty"
};
export default ___CSS_LOADER_EXPORT___;
