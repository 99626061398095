// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baas_styles_content__8fJc1 {\n  display: flex;\n  flex-direction: column;\n  padding: 8px 8px 16px;\n}\n\n.baas_styles_controlRow__nqcYu {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n}\n\n.baas_styles_controlLabel__LfAOg {\n  display: block;\n  margin-bottom: 4px;\n  color: #4f4f4f;\n  font-size: 12px;\n}\n\n.baas_styles_errMsg__DCqhP {\n  height: 14px;\n  padding: 2px 5px;\n  color: #ff5a5a;\n  font-size: 12px;\n}\n\n.baas_styles_cardFooter__DCJt0 {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-end;\n  padding: 16px 8px 8px;\n  border-top: 1px solid #f4f4f4;\n}\n\n.baas_styles_cardFooter__DCJt0 > * {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/ExportModals/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,qBAAqB;EACrB,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  padding: 8px 8px 16px;\n}\n\n.controlRow {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n}\n\n.controlLabel {\n  display: block;\n  margin-bottom: 4px;\n  color: #4f4f4f;\n  font-size: 12px;\n}\n\n.errMsg {\n  height: 14px;\n  padding: 2px 5px;\n  color: #ff5a5a;\n  font-size: 12px;\n}\n\n.cardFooter {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-end;\n  padding: 16px 8px 8px;\n  border-top: 1px solid #f4f4f4;\n}\n\n.cardFooter > * {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "baas_styles_content__8fJc1",
	"controlRow": "baas_styles_controlRow__nqcYu",
	"controlLabel": "baas_styles_controlLabel__LfAOg",
	"errMsg": "baas_styles_errMsg__DCqhP",
	"cardFooter": "baas_styles_cardFooter__DCJt0"
};
export default ___CSS_LOADER_EXPORT___;
