import React from "react";
import { Button, Title } from "@skyportal/ui-kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ContractDropdown from "containers/ContractDropdown";
import { startPageSelectors } from "store/startPage";
import ModuleBreadcrumbs from "containers/ModuleBreadcrumbs";

import styles from "./styles.module.css";

export interface PageHeaderProps {
  title: React.ReactNode;
  withNavigationLinks?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, withNavigationLinks = false }) => {
  const { t } = useTranslation("backupStartPage");

  const commCellLinkUrl = useSelector(startPageSelectors.getCommCellLinkUrl);
  return (
    <header className={styles.root}>
      <div className={styles.titleBlock}>
        <ModuleBreadcrumbs />
        <Title level={2} className={styles.title}>
          {title}
        </Title>
      </div>
      <div className={styles.subtitleBlock}>
        <ContractDropdown className={styles.contractDropdown} />
        {withNavigationLinks && (
          <div>
            <a href={commCellLinkUrl} target="_blank" rel="noreferrer">
              <Button type="secondary" rightIcon="arrow-right" disabled={!commCellLinkUrl}>
                {t("commcellLinkText")}
              </Button>
            </a>
          </div>
        )}
      </div>
    </header>
  );
};

export default PageHeader;
