import React from "react";
import { Button, Title } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientsSelectors } from "store/clients";
import { RequestStatus } from "types/common.types";
import { ClientModel, LabelModel } from "types/clients.types";
import { startPageSelectors } from "store/startPage";
import ModuleBreadcrumbs from "containers/ModuleBreadcrumbs";

import styles from "./styles.module.css";

export interface ClientDetailsPageHeaderProps {
  client?: ClientModel;
}

const ClientDetailsPageHeader = ({ client }: ClientDetailsPageHeaderProps) => {
  const { t } = useTranslation("clientDetailsPage");
  const commCellLinkUrl = useSelector(startPageSelectors.getCommCellLinkUrl);
  const { requestStatus } = useSelector(clientsSelectors.getClients);

  return (
    <header className={styles.root}>
      <div className={styles.titleBlock}>
        <ModuleBreadcrumbs />
        <Title level={2} className={styles.title}>
          {t("title")}
        </Title>
      </div>
      {requestStatus === RequestStatus.SUCCESS && (
        <div className={styles.subtitleBlock}>
          <div className={styles.subtitle}>{`${client?.clientName} ${
            (client?.labels as LabelModel[]).length ? "(" : ""
          }${
            (client?.labels as LabelModel[]).length
              ? (client?.labels as LabelModel[]).map((label) => label.name).join(", ")
              : ""
          }${(client?.labels as LabelModel[]).length ? ")" : ""}`}</div>
          <div className={styles.clientLinksBlock}>
            <Link to="/baas/clients">
              <Button rightIcon="arrow-right">{t("backToClientList")}</Button>
            </Link>
            <a href={commCellLinkUrl} target="_blank" rel="noreferrer">
              <Button type="secondary" rightIcon="arrow-right" disabled={!commCellLinkUrl}>
                {t("commcellLinkText")}
              </Button>
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default ClientDetailsPageHeader;
