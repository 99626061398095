import i18n from "i18next";

export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

export const getBreadcrumbsConfig = (): BreadcrumbsConfigItem[] => {
  const DefaultCrumb: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:Start"),
    path: "/start",
  };

  const Backup: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:Backup"),
    path: "/baas/overview",
  };

  const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
    {
      label: Backup.label,
      crumbs: [DefaultCrumb],
      match: {
        path: Backup.path,
        exact: true,
      },
    },
    {
      label: i18n.t("breadcrumbs:O365 Usage"),
      crumbs: [DefaultCrumb, Backup],
      match: {
        path: "/baas/o365-usage",
      },
    },
    {
      label: i18n.t("breadcrumbs:Usage Report"),
      crumbs: [DefaultCrumb, Backup],
      match: {
        path: "/baas/usage-report",
      },
    },
    {
      label: i18n.t("breadcrumbs:Clients"),
      crumbs: [DefaultCrumb, Backup],
      match: {
        path: "/baas/clients",
        exact: true,
      },
    },
    {
      label: i18n.t("breadcrumbs:Client Details"),
      crumbs: [DefaultCrumb, Backup],
      match: {
        path: "/baas/clients",
      },
    },
    {
      label: i18n.t("breadcrumbs:Backup Jobs"),
      crumbs: [DefaultCrumb, Backup],
      match: {
        path: "/baas/backup-jobs",
      },
    },
    {
      label: i18n.t("breadcrumbs:Restore Jobs"),
      crumbs: [DefaultCrumb, Backup],
      match: {
        path: "/baas/restore-jobs",
      },
    },
  ];

  return breadcrumbsConfig;
};
