// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baas_styles_boxRoot__qGlnz {\n  display: flex;\n  justify-content: flex-end;\n  padding: 8px 16px;\n}\n\n.baas_styles_boxRoot__qGlnz > div > div:first-child {\n  display: none;\n}\n\n.baas_styles_pagination__xH\\+L9 {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/PaginationBox/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".boxRoot {\n  display: flex;\n  justify-content: flex-end;\n  padding: 8px 16px;\n}\n\n.boxRoot > div > div:first-child {\n  display: none;\n}\n\n.pagination {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxRoot": "baas_styles_boxRoot__qGlnz",
	"pagination": "baas_styles_pagination__xH+L9"
};
export default ___CSS_LOADER_EXPORT___;
