// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baas_styles_chart__SrAXc {\n  position: relative;\n  overflow: auto;\n  height: 350px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/O365UsagePageContent/tabs/O365UsageReport/O365UsageChart/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;AACf","sourcesContent":[".chart {\n  position: relative;\n  overflow: auto;\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": "baas_styles_chart__SrAXc"
};
export default ___CSS_LOADER_EXPORT___;
