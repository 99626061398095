import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@skyportal/ui-kit";

import styles from "./styles.module.css";

const ClientDetailsPageFallback: React.FC = () => {
  const { t } = useTranslation("clientDetailsPage");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.clientDetailsErrorFallback}>
      <span>{t("notFoundClientErrorMsg")}</span>
      <div className={styles.clientDetailsErrorFallbackButtons}>
        <Link to="/baas/overview">
          <Button rightIcon="arrow-right">{t("goToDashboard")}</Button>
        </Link>
        <Link to="/baas/clients">
          <Button type="secondary" rightIcon="arrow-right">
            {t("goToClientList")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ClientDetailsPageFallback;
