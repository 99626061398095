// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baas_styles_root__y5d6e {\n  margin-bottom: var(--padding-medium);\n}\n\n.baas_styles_titleBlock__MbicK {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n.baas_styles_title__OdLDj {\n  margin: 0;\n  font-size: 28px;\n  font-weight: 700;\n}\n\n.baas_styles_subtitleBlock__46lWL {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n\n.baas_styles_subtitle__3M25V {\n  padding: 8px 0;\n  font-weight: 700;\n}\n\n.baas_styles_clientLinksBlock__VyhtN a:first-child {\n  margin-right: 16px;\n}\n\n@media (max-width: 767px) {\n  .baas_styles_titleBlock__MbicK,\n  .baas_styles_subtitleBlock__46lWL {\n    display: block;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/ClientDetailsPageContent/ClientDetailsPageHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,SAAS;EACT,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;;IAEE,cAAc;EAChB;AACF","sourcesContent":[".root {\n  margin-bottom: var(--padding-medium);\n}\n\n.titleBlock {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n.title {\n  margin: 0;\n  font-size: 28px;\n  font-weight: 700;\n}\n\n.subtitleBlock {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n\n.subtitle {\n  padding: 8px 0;\n  font-weight: 700;\n}\n\n.clientLinksBlock a:first-child {\n  margin-right: 16px;\n}\n\n@media (max-width: 767px) {\n  .titleBlock,\n  .subtitleBlock {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "baas_styles_root__y5d6e",
	"titleBlock": "baas_styles_titleBlock__MbicK",
	"title": "baas_styles_title__OdLDj",
	"subtitleBlock": "baas_styles_subtitleBlock__46lWL",
	"subtitle": "baas_styles_subtitle__3M25V",
	"clientLinksBlock": "baas_styles_clientLinksBlock__VyhtN"
};
export default ___CSS_LOADER_EXPORT___;
